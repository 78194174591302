import React, { useState } from "react";
import { Modal } from "antd";
import { Button, Input, Space, message } from "antd";
import styled from "styled-components";
import { updateProfil, refresh } from "../api/auth";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import TreeImportDaxium from "./TreeImportDaxium";

const ModalTreeDaxium: React.FC<modalProps> = ({
  visible,
  change,
  dataImport,
  update,
}) => {
  const handleCancel = () => {
    change(false);
    clearData();
  };

  // Cleaning the form
  const clearData = () => {};

  const handleOk = () => {
    change(false);
    clearData();
  };

  return (
    <Modal
      style={{ top: 20 }}
      title=""
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={560}
      centered
      footer={[
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* {
            <Button
              key="submit"
              size={"large"}
              style={{
                background: "#4e8ccb",
                color: "white",
                fontSize: "1rem",
                paddingLeft: "13px",
                paddingRight: "13px",
                textAlign: "center",
                width: "auto",
              }}
              onClick={renitialise}
            >
              Renitialiser
            </Button>
          } */}
          <Button
            key="submit"
            size={"large"}
            style={{
              background: "#4e8ccb",
              color: "white",
              fontSize: "1rem",
              paddingLeft: "13px",
              paddingRight: "13px",
              textAlign: "center",
              width: "auto",
            }}
            onClick={handleOk}
          >
            Fermer
          </Button>
        </div>,
      ]}
    >
      <div
        style={{
          paddingTop: "10px",
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <Content>Trie des colonnes</Content>

        <TreeImportDaxium
          dataSource={dataImport}
          loading={false}
          setDataSource={update}
        />
      </div>
    </Modal>
  );
};

export default ModalTreeDaxium;
const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerInput = styled(Space)`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.p`
  font-size: 1.02rem;
  width: 150px;
  text-align: end;
  align-items: left;
  font-weight: 650;
`;

const Content = styled.p`
  margin-top: 10px;
  background-color: #4e8ccb;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  text-tranform: uppercase;
  border-radius: 5px;
  padding: 3px;
`;
interface modalProps {
  visible: boolean;
  change: Function;
  dataImport: { name: string; position: number; isDraggable: boolean }[];
  update: (data: any) => void;
}
