import api from ".";
import { FormStructure, ListDaxium } from "../interface/Formulaire";
import {
  ConnectorConfigResponse,
  typeObjetDaxium,
} from "../interface/configConnector";

const getApplication = async ({
  queryKey,
}: {
  queryKey: ApplicationQueryKey;
}) => {
  const [_, idCompte] = queryKey;

  const res = await api.get("/config-connecteur/application/" + idCompte);
  const response = JSON.parse(res.data);
  return response;
};

const getStructure = async ({ queryKey }: { queryKey: StructureQueryKey }) => {
  const [_, data] = queryKey;
  const res = await api.get(
    "/config-connecteur/structure/" + data.idCompte + "/" + data.short
  );
  const response: FormStructure[] = JSON.parse(res.data);
  return response;
};

const getList = async ({ queryKey }: { queryKey: ListQueryKey }) => {
  const [_, data] = queryKey;
  const res = await api.get(
    "/config-connecteur/list/" + data.idCompte + "/" + data.short
  );
  const response: ListDaxium[] = JSON.parse(res.data);

  return response;
};

const getReport = async (data: {
  idCompte: string;
  short: string;
  idStructure: string;
}) => {
  const res = await api.get(
    "/config-connecteur/templates/" +
      data.idCompte +
      "/" +
      data.short +
      "/" +
      data.idStructure
  );
  const response = JSON.parse(res.data);
  return response;
};

const createConfig = async (data: {
  idConnecteur: string;
  idOrganisation: string;
  idCompte: string;
  nom: string;
  short: string;
  idElement: string;
  actif: boolean;

  getReport: boolean;
  templates: string;

  values: any;
  item: string;
  idFichier: string;
  mappings: any;
  url: string;
  fileInDriveId: string;
  fileInDrive: boolean;
  idDrive: string;
  siteInDrive: string | null;

  idObjetDaxium: string;
  nomObjetDaxium: string;
  typeObjetDaxium: typeObjetDaxium;
  data: { position: number; name: string }[];

  cron: any;
  ignoreFiche: boolean;
}) => {
  const res = await api.post("/config-connecteur", data);
  const response = JSON.parse(res.data);
  return response;
};

const updateConfig = async (data: {
  idConfigConnecteur: string;
  data: {
    idConnecteur: string;
    idOrganisation: string;
    idCompte: string;
    nom: string;
    short: string;
    idElement: string;
    actif: boolean;

    getReport: boolean;
    templates: string;

    values: any;
    item: string;
    idFichier: string;
    mappings: any;
    url: string;
    fileInDriveId: string;
    fileInDrive: boolean;
    idDrive: string;
    siteInDrive: string | null;

    idObjetDaxium: string;
    nomObjetDaxium: string;
    typeObjetDaxium: typeObjetDaxium;
    data: { position: number; name: string }[];
    cron: any;
    ignoreFiche: boolean;
  };
}) => {
  const res = await api.patch(
    "/config-connecteur/" + data.idConfigConnecteur,
    data.data
  );
  const response = JSON.parse(res.data);
  return response;
};

const start = async (idConfigConecteur: string) => {
  const res = await api.post("/config-connecteur/import/" + idConfigConecteur);
};

const deleteConfig = async (idConfigConecteur: string) => {
  const res = await api.delete("/config-connecteur/" + idConfigConecteur);
};

const getConfigMapping = async (idConfigConecteur: string) => {
  const res = await api.get(
    "/config-connecteur/relations/" + idConfigConecteur
  );
  const response = JSON.parse(res.data);
  return response;
};

const changeStatus = async (data: {
  idConfigConecteur: string;
  actif: boolean;
}) => {
  const res = await api.patch(
    "/config-connecteur/status/" + data.idConfigConecteur,
    { actif: data.actif }
  );
  const response = JSON.parse(res.data);
  return response;
};

const getConfigByConnecteur = async ({
  queryKey,
}: {
  queryKey: ConfigConnectorQueryKey;
}) => {
  const [_, idConnector] = queryKey;

  const res = await api.get("/config-connecteur/by-me/" + idConnector);

  const response: ConnectorConfigResponse[] = JSON.parse(res.data);

  return response;
};

export {
  getConfigByConnecteur,
  getApplication,
  getStructure,
  createConfig,
  getReport,
  start,
  deleteConfig,
  getConfigMapping,
  updateConfig,
  changeStatus,
  getList,
};

type ConfigConnectorQueryKey = ["configConnector", string];

type ApplicationQueryKey = ["application", string];
type StructureQueryKey = ["formulaire", { idCompte: string; short: string }];
type ListQueryKey = ["lists", { idCompte: string; short: string }];
