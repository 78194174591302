interface ChampMapping {
  id: string;
  idImportDaxium: string;
  idTypeChampMapping: string | null;
  nomChamp: string;
  nomChampDaxium: string;
  libelleChampDaxium: string;
  unique: boolean;
  position: number;
  type: string | null;
  createdDate: string;
  updatedDate: string;
  data: { name: string; position: number }[];
}
interface ImportDaxium {
  id: string;
  idFichier: string | null;
  idCron: string | null;
  url: string;
  item: string;
  nomObjetDaxium: string;
  idObjetDaxium: string;
  typeObjetDaxium: string;
  createdDate: string;
  updatedDate: string;
  fileInDriveId: string;
  fileInDrive: boolean;
  siteInDrive: string | null;
  idDrive: string;
  cron: any | null; // Remplacez 'any' par le type approprié si possible
  champMappings: ChampMapping[];
  ignoreFiche: boolean;
  smartImport: boolean;
  data: { position: number; name: string }[];
}

interface ConfigConnecteurChamp {
  id: string;
  idChamp: string;
  idConfigConnecteur: string;
  value: string;
}

interface ConnectorConfigResponse {
  id: string;
  idConnecteur: string;
  idOrganisation: string;
  idCompte: string;
  idUtilisisateur: string;
  idImportDaxium: string;
  nom: string;
  version: number;
  templateId: string | null;
  templates: string;
  short: string;
  idElement: string;
  actif: boolean;
  getReport: boolean;
  futureImportDate: string | null;
  createdDate: string;
  updatedDate: string;
  configConnecteurChamps: ConfigConnecteurChamp[];
  importDaxium: ImportDaxium;
}

export type {
  ChampMapping,
  ConfigConnecteurChamp,
  ConnectorConfigResponse,
  ImportDaxium,
};

export enum typeObjetDaxium {
  list = "list",
  submission = "submission",
}
