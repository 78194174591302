import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import styled from "@emotion/styled";
import { memo } from "react";

const ItemType = "ITEM";

interface TreeProps {
  loading: boolean;
  dataSource: { position: number; name: string; isDraggable: boolean }[];
  setDataSource: (data: any) => void;
}

interface DataItem {
  id: string;
  position: number;
  name: string;
  isDraggable: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const TreeImportDaxium: React.FC<TreeProps> = ({
  dataSource,
  loading,
  setDataSource,
}) => {
  const [data, setData] = useState<DataItem[]>(
    dataSource.map((item, index) => ({
      id: index.toString() + item.name,
      position: index,
      name: item.name,
      isDraggable: item.isDraggable,
    }))
  );

  const handleDelete = useCallback(
    (id: string) => {
      const updatedData = data.filter((item) => item.id !== id);
      const reindexedData = updatedData.map((item, index) => ({
        ...item,
        position: index,
      }));
      setData(reindexedData);
    },
    [data, setDataSource]
  );

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setData((prevData) => {
        const updatedData = [...prevData];
        const [draggedItem] = updatedData.splice(dragIndex, 1);
        updatedData.splice(hoverIndex, 0, draggedItem);

        const reindexedData = updatedData.map((item, index) => ({
          ...item,
          position: index,
        }));

        return reindexedData;
      });
    },
    [setDataSource]
  );

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  return (
    <Container>
      {loading ? (
        <LoadingText>Chargement...</LoadingText>
      ) : (
        <DragDropList>
          {data.map((item, index) => (
            <DraggableItem
              key={item.id}
              index={index}
              id={item.id}
              value={item.name}
              moveItem={moveItem}
              handleDelete={handleDelete}
              isDraggable={item.isDraggable}
            />
          ))}
        </DragDropList>
      )}
    </Container>
  );
};

interface DraggableItemProps {
  id: string;
  value: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  handleDelete: (id: string) => void;
  isDraggable: boolean;
}

interface StyledItemProps {
  isDragging?: boolean;
  isOver?: boolean;
}

const DraggableItem: React.FC<DraggableItemProps> = memo(
  ({ id, value, index, moveItem, handleDelete, isDraggable }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag({
      type: ItemType,
      item: () => ({ index, id, type: ItemType }),
      canDrag: isDraggable,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [{ isOver, handlerId }, drop] = useDrop<
      DragItem,
      void,
      { isOver: boolean; handlerId: Identifier | null }
    >({
      accept: ItemType,
      collect(monitor) {
        return {
          isOver: monitor.isOver(),
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item: DragItem, monitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset() as XYCoord;
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Déplacement vers le haut
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Déplacement vers le bas
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        moveItem(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    drag(drop(ref));

    return (
      <ItemParent
        ref={isDraggable ? ref : null}
        data-handler-id={handlerId}
        isDragging={isDragging}
        isOver={isOver}
      >
        <Item isOver={isOver}>
          <ItemText>{value}</ItemText>
          {isDraggable && (
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(id);
              }}
            >
              ×
            </DeleteButton>
          )}
        </Item>
      </ItemParent>
    );
  }
);

DraggableItem.displayName = "DraggableItem";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  height: 400px;
  overflow: auto;
  padding: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const LoadingText = styled.span`
  color: #666;
  font-size: 1rem;
  margin: 20px 0;
`;

const DragDropList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const ItemParent = styled.div<StyledItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.isDragging ? "grabbing" : "grab")};
  background-color: transparent;
  width: 100%;
  opacity: ${(props) => (props.isDragging ? 0.4 : 1)};
  transition: all 0.2s ease;
  position: relative;
  padding: 2px 0;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
    height: 2px;
    background-color: #2196f3;
    opacity: ${(props) => (props.isOver ? 1 : 0)};
    transition: opacity 0.2s ease;
    ${(props) => (props.isOver ? "top: -2px" : "")}
  }
`;

const Item = styled.div<StyledItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 30px;
  background-color: white;
  padding: 0 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.2s ease;
  transform: ${(props) => (props.isOver ? "scale(1.02)" : "scale(1)")};
  border: ${(props) =>
    props.isOver ? "1px solid #2196f3" : "1px solid transparent"};

  &:hover {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }
`;

const ItemText = styled.p`
  color: #3e5366;
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

const DeleteButton = styled.button`
  color: #ff4444;
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #ff0000;
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }
`;

export default TreeImportDaxium;
