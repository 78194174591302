import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Button, Input, Space, Select, message } from "antd";
import styled from "styled-components";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  updateDrive,
  getAllDriveCategorie,
  getOneDrive,
  getSharePointSites,
} from "../api/drive";
import { getAllOrganisation } from "../api/organisation";
import { refresh } from "../api/auth";
import {
  getCodeUrl,
  getDriveNode,
  getFolderbyDrive,
  getFileInfoDrive,
} from "../api/drive";
import { AccountDrive, FileItem, Site } from "../interface/drive";
import ModalFolderDrive from "./ModalFolderDrive";
import { CustomDataNode } from "./ModalFolderDrive";
// import { Site } from "../interface/drive";

const ModalModifiedDrive: React.FC<modalProps> = ({ visible, change, id }) => {
  const queryClient = useQueryClient();
  const [messageApi, contextHolder] = message.useMessage();
  const [compte, setCompte] = useState<accountInfo | null>(null);
  const [titre, setTitre] = useState<string>("");
  const [drive, setDrive] = useState<string | null>(null);
  const [organisation, setOrganisation] = useState<string>("");
  const [account, setAccount] = useState<AccountDrive>();
  const [modalDrive, setModalDrive] = useState(false);
  const [filteItems, setFilteItems] = useState<FileItem[]>([]);
  const [selectedFile, setSelectedFile] = useState<CustomDataNode>();
  const [name, setName] = useState<string>("");
  const [root, setRoot] = useState<string>("");
  const [site, setSite] = useState("");
  const [driveFile, setDriveFile] = useState<string>("");

  const userInfo = useQuery({
    queryKey: ["userInfo"],
    queryFn: refresh,
  });

  const urlInfo = useQuery({
    queryKey: ["urlAccount"],
    queryFn: getCodeUrl,
  });

  const FileInfo = useQuery({
    queryKey: [
      "FileInfo",
      {
        idDrive: id || "",
        idFile: root || "",
        siteInDrive: site || "",
      },
    ],
    queryFn: getFileInfoDrive,
    enabled: Boolean(id) && Boolean(root) && root !== "onedrive",
  });

  const getAllSites = useQuery({
    queryKey: [
      "Sites",
      {
        idDrive: id || "",
      },
    ],
    queryFn: getSharePointSites,
    enabled: Boolean(id),
  });

  const updateAccountMutation = useMutation({
    mutationFn: updateDrive,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["drivesAccount"] });
      messageApi.open({
        type: "success",
        content: "Le compte a été mis à jour  succès",
        duration: 2,
      });
      handleCancel();
    },
    onError: (err) => {
      messageApi.open({
        type: "error",
        content: "Une erreur est survenue lors de la mise à jour ",
        duration: 2,
      });
    },
  });

  const CategorieDrive = useQuery({
    queryKey: ["CategorieDrive"],
    queryFn: getAllDriveCategorie,
  });

  const Organisations = useQuery({
    queryKey: ["Organisations"],
    queryFn: getAllOrganisation,
  });
  const driveInfo = useQuery({
    queryKey: ["driveInfo", id],
    queryFn: getOneDrive,
  });
  const FolderDrive = useQuery({
    queryKey: ["FolderDrive", id || ""],
    queryFn: getFolderbyDrive,
  });

  const formattedDrives: { label: string; value: string; key: string }[] = [];
  const formattedOrganisation: { label: string; value: string; key: string }[] =
    [{ label: "Aucune", value: "", key: "aucune" }];

  if (CategorieDrive.isSuccess && CategorieDrive.data) {
    for (let i = 0; i < CategorieDrive.data.length; i++) {
      const element = CategorieDrive.data[i];
      formattedDrives.push({
        label: element.nom,
        value: element.id,
        key: element.id,
      });
    }
  }

  if (Organisations.isSuccess && Organisations.data) {
    for (let i = 0; i < Organisations.data.length; i++) {
      const element = Organisations.data[i];
      formattedOrganisation.push({
        label: element.nom,
        value: element.id,
        key: element.id,
      });
    }
  }

  useEffect(() => {
    if (driveInfo.data) {
      setTitre(driveInfo.data.nom);
      setDrive(driveInfo.data.idCategorieDrive);
      setOrganisation(
        driveInfo.data.idOrganisation ? driveInfo.data.idOrganisation : ""
      );
      setAccount(driveInfo.data);
      setRoot(driveInfo.data.root);
      setSite(driveInfo.data.site);
      if (driveInfo.data.site === "" && driveInfo.data.root === "onedrive") {
        setDriveFile("One Drive");
      }
    }
  }, [id, driveInfo.data]);

  useEffect(() => {
    if (FolderDrive.data) {
      const data: FileItem[] = [];
      for (let i = 0; i < FolderDrive.data.length; i++) {
        const element = FolderDrive.data[i];
        data.push(element);
      }
      setFilteItems(data);
    } else {
      setFilteItems([]);
      // setUrl("");
      // setDriveFile("");
    }
  }, [FolderDrive.isSuccess]);

  const getCode = (url: string) => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const windowWidth = 500;
    const windowHeight = 600;

    const left = (screenWidth - windowWidth) / 2;
    const top = (screenHeight - windowHeight) / 2;

    const authWindow = window.open(
      url,
      "Authentication",
      `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
    );

    const checkForAuthorizationCode = (event: any) => {
      if (authWindow) {
        if (event.source == authWindow) {
          if (event.data) {
            const data = JSON.parse(event.data);
            setCompte(data);
          }
          try {
            window.removeEventListener("message", checkForAuthorizationCode);
            authWindow.close();
          } catch (error) {
            console.error("Error checking for authorization code:", error);
          }
        }
      }
    };

    // Écouter les changements d'URL dans la fenêtre d'authentification
    window.addEventListener("message", checkForAuthorizationCode);
  };

  const submit = () => {
    try {
      if (!titre.trim()) throw new Error("Vous n'avez pas entré de Titre");
    } catch (e: any) {
      messageApi.open({
        type: "warning",
        content: e.message ? e.message : "",
        duration: 2,
      });
      return;
    }

    if (titre && drive && account && CategorieDrive.data) {
      const idProprietaire = userInfo.data?.id;
      const tockenRafraichissement = compte
        ? compte.data.refreshToken
        : account.tockenRafraichissement;
      const tockenAccess = compte
        ? compte.data.accessToken
        : account.tockenAccess;
      const dateExpirationTocken = compte
        ? compte.data.createdAt + compte.data.expiresIn
        : account.dateExpirationTocken;

      if (
        id &&
        drive &&
        idProprietaire &&
        tockenAccess &&
        tockenRafraichissement &&
        dateExpirationTocken
      ) {
        updateAccountMutation.mutate({
          idAccountDrive: id,
          idCategorieDrive: drive,
          idOrganisation: organisation,
          idProprietaire,
          nom: titre,
          tockenRafraichissement,
          tockenAccess,
          dateExpirationTocken,
          root,
          site,
        });
      }
    }
  };

  useEffect(() => {
    if (selectedFile) {
      const title = selectedFile.title ? selectedFile?.title : "";
      const idSite = selectedFile.siteInDrive ? selectedFile.siteInDrive : "";
      let idFolder = selectedFile.key ? selectedFile.key : "";
      if (typeof idFolder === "string" && idFolder.includes("sharepoint")) {
        idFolder = "";
      }
      setDriveFile(title.toString());

      if (idFolder !== "onedrive") {
        setRoot(idFolder.toString());
        setSite(idSite);
      } else {
        setDriveFile(idFolder);
        setRoot("onedrive");
        setSite("");
      }
    }
  }, [selectedFile]);

  useEffect(() => {
    if (FileInfo.data && root) {
      setDriveFile(FileInfo.data.name);
    }
  }, [FileInfo.data, root]);

  useEffect(() => {
    if (getAllSites.data && site && root === "") {
      const name =
        getAllSites.data.find((item: Site) => item.site === site)?.name || "";
      setDriveFile(name);
    }
  }, [getAllSites.data, site]);

  const handleOk = () => {
    clearData();
    change(false);
  };

  const handleCancel = () => {
    clearData();
    change(false);
  };

  // Cleaning the form
  const clearData = () => {
    setTitre("");
    setDrive(null);
    setOrganisation("");
    setCompte(null);
  };

  return (
    <>
      {modalDrive && (
        <ModalFolderDrive
          visible={modalDrive}
          data={filteItems}
          updateData={setFilteItems}
          change={setModalDrive}
          updateFile={setSelectedFile}
          fileSelected={selectedFile || null}
          idFileSelected={""}
          isDirectory={true}
          drive={id}
        />
      )}
      <Modal
        style={{ top: 20 }}
        title=""
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={560}
        centered
        key="ModifiedDrive"
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              key="back"
              size={"large"}
              onClick={handleCancel}
              style={{
                fontSize: "1rem",
                padding: "5px",
                textAlign: "center",
                width: "120px",
              }}
            >
              ANNULER
            </Button>

            <Button
              key="update"
              size={"large"}
              style={{
                background: "#4e8ccb",
                color: "white",
                fontSize: "1rem",
                paddingLeft: "13px",
                paddingRight: "13px",
                textAlign: "center",
                width: "auto",
              }}
              // disabled={Boolean(urlInfo.data?.url)}
              onClick={() =>
                compte ? setCompte(null) : getCode(urlInfo.data?.url || "")
              }
              loading={urlInfo.isLoading}
            >
              {compte ? "Supprimer Le compte" : "Changer de Compte"}
            </Button>
            <Button
              key="submit"
              size={"large"}
              style={{
                background: "#4e8ccb",
                color: "white",
                fontSize: "1rem",
                paddingLeft: "13px",
                paddingRight: "13px",
                textAlign: "center",
                width: "auto",
              }}
              // disabled={true}
              onClick={submit}
              loading={updateAccountMutation.isPending}
            >
              MODIFIER LE DRIVE
            </Button>
          </div>,
        ]}
      >
        <div style={{ padding: "10px" }}>
          {contextHolder}
          <Content>AJOUT DU DRIVE</Content>
          <Container>
            <ContainerInput direction="horizontal">
              <Title>
                Titre <span style={{ color: "red" }}>*</span> :
              </Title>
              <Input
                size="large"
                style={{ height: 40, width: 300 }}
                value={titre}
                onChange={(e) => {
                  setTitre(e.target.value);
                }}
              />
            </ContainerInput>
            <ContainerInput direction="horizontal">
              <Title>
                Fournisseur <span style={{ color: "red" }}>*</span> :{" "}
              </Title>
              <Select
                showSearch={true}
                optionFilterProp="label"
                style={{ width: 300 }}
                size="large"
                value={drive}
                onChange={setDrive}
                options={formattedDrives}
                loading={CategorieDrive.isLoading}
              />
            </ContainerInput>
            <ContainerInput direction="horizontal">
              <Title>Organisation : </Title>
              <Select
                showSearch={true}
                optionFilterProp="label"
                style={{ width: 300 }}
                size="large"
                value={organisation}
                onChange={setOrganisation}
                options={formattedOrganisation}
                loading={Organisations.isLoading}
              />
            </ContainerInput>
            <ContainerInput direction="horizontal">
              <Button
                key="submit"
                size={"large"}
                onClick={() => setModalDrive(!modalDrive)}
                style={{
                  background: filteItems.length === 0 ? "" : "#4e8ccb ",
                  color: filteItems.length === 0 ? "" : "white",
                  fontSize: "1rem",
                  textAlign: "center",
                }}
                disabled={filteItems.length === 0}
                loading={FolderDrive.isLoading}
              >
                Choix du dossier parent
              </Button>
              <Input
                size="large"
                style={{ height: 40, width: 250 }}
                disabled={true}
                value={driveFile ? driveFile : "onedrive"}
              />
            </ContainerInput>
          </Container>
        </div>
        {contextHolder}
      </Modal>
    </>
  );
};

export default ModalModifiedDrive;
const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerInput = styled(Space)`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.p`
  font-size: 1.02rem;
  width: 150px;
  text-align: end;
  align-items: left;
  font-weight: 650;
`;

const Content = styled.p`
  margin-top: 10px;
  background-color: #4e8ccb;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  text-tranform: uppercase;
  border-radius: 5px;
  padding: 3px;
`;
interface modalProps {
  visible: boolean;
  change: Function;
  id: string;
}

interface accountInfo {
  status: "ok|ko";
  data: {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    createdAt: number;
    scope: string;
  };
}
