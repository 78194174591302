import React, { useState } from "react";
import { Table } from "antd";

interface Table {
  columns?: any;
  dataSource?: any[];
  pageSize?: number;
  loading?: boolean;
}

const TableCustom: React.FC<Table> = ({
  columns,
  dataSource,
  pageSize,
  loading,
}) => {
  const [size, setSize] = useState(pageSize);
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      bordered
      locale={{ emptyText: "Aucune donnée à Afficher" }}
      size="small"
      pagination={{
        hideOnSinglePage: true,
        pageSize: size,
        onChange(page, pageSize) {
          setSize(pageSize);
        },
        defaultPageSize: 2,
        pageSizeOptions: [1, 2, 3],
      }}
    />
  );
};
export default TableCustom;
