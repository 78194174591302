import React from "react";
import { Modal, Spin } from "antd";
import { Button, Input, Space, Select, message } from "antd";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { Tree } from "antd";
import type { DataNode } from "antd/es/tree";
import { FileItem } from "../interface/drive";
import type { DirectoryTreeProps, EventDataNode } from "antd/es/tree";
import { getDriveNode } from "../api/drive";
import {
  InvalidateQueryFilters,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
export type { CustomDataNode };

const ModalFolderDrive: React.FC<modalProps> = ({
  visible,
  change,
  data,
  fileSelected,
  updateFile,
  idFileSelected,
  isDirectory,
  drive,
  updateData,
  idSite,
  idRoot,
}) => {
  const queryClient = useQueryClient();
  const handleOk = () => {
    change(false);
  };

  const submit = () => {
    change(false);
  };

  const convertToDataNode = (item: FileItem): CustomDataNode => {
    const dataNode: CustomDataNode = {
      key: item.id,
      title: item.name,
      icon: item.type === "folder" ? "folder" : "file",
      url: item.url ? item.url : "",
      siteInDrive: item.site ? item.site : "",
      isLeaf: item.type === "file",
    };

    // Vérifie si le champ children existe et n'est pas vide
    if (item.children && item.children.length > 0) {
      dataNode.children = item.children.map(convertToDataNode);
      dataNode.url = item.url;
      dataNode.siteInDrive = item.site;
    }

    return dataNode;
  };

  const convertDataToTree = (data: FileItem[]): DataNode[] => {
    return data.map(convertToDataNode);
  };
  const [messageApi, contextHolder] = message.useMessage();
  const [id, setId] = useState<string | null>(idRoot ? idRoot : "");
  const [site, setSite] = useState<string | null>(idSite ? idSite : "");
  let treeData: DataNode[] = convertDataToTree(data);
  const [showIcon, setShowIcon] = useState<boolean>(false);

  function getElementByKeyRecursive(
    data: CustomDataNode[],
    key: string
  ): CustomDataNode | null {
    for (const item of data) {
      if (item.key === key) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const childResult = getElementByKeyRecursive(item.children, key);
        if (childResult) {
          return childResult;
        }
      }
    }
    return null;
  }

  function ajouterChildrenRecursif(
    oldArray: FileItem[],
    targetId: string,
    listeFichiers: FileItem[]
  ) {
    for (let i = 0; i < oldArray.length; i++) {
      const element = oldArray[i];
      if (element.id === targetId) {
        element.size = listeFichiers.length;
        element.children = listeFichiers;
        return oldArray;
      } else if (element.children) {
        const result = ajouterChildrenRecursif(
          element.children,
          targetId,
          listeFichiers
        );
        if (result !== element.children) {
          element.children = result;
          return oldArray;
        }
      }
    }
    return oldArray;
  }

  const onSelect = (selectedKeys: React.Key[]) => {
    const fileSelected = getElementByKeyRecursive(
      treeData,
      String(selectedKeys[0]) || ""
    );

    if (isDirectory && fileSelected && fileSelected.isLeaf) {
      messageApi.open({
        type: "warning",
        content: "Vous devez sélectionner un dossier ",
        duration: 2,
      });
    }
    if (isDirectory && fileSelected && fileSelected.key == "sharepoint") {
      messageApi.open({
        type: "warning",
        content: "Le dossier SharePoint ne peut pas être un dossier parent ",
        duration: 2,
      });
      return;
    }
    // console.log(fileSelected);
    if (fileSelected && !fileSelected.isLeaf && isDirectory) {
      updateFile(fileSelected);

      handleCancel();
    } else if (fileSelected && fileSelected.isLeaf && !isDirectory) {
      updateFile(fileSelected);
      handleCancel();
    }
  };

  const getNode = useQuery({
    queryKey: [
      "DriveNodeFile",
      { id: id || "", site: site || "", drive: drive || "" },
    ],
    queryFn: getDriveNode,
    enabled: Boolean(drive),
  });

  const onExpand: DirectoryTreeProps["onExpand"] = (keys, info) => {
    if (info.expanded && info.node.key.toString() !== "sharepoint") {
      const s =
        (info.node as CustomEventDataNode)?.siteInDrive?.toString() || "";

      let idFolder = info.node.key.toString();
      if (idFolder?.includes("sharepoint")) idFolder = "";
      setId(idFolder === "onedrive" ? "" : idFolder);
      setSite(s);
      queryClient.invalidateQueries({ queryKey: ["DriveNodeFile"] });
    }
  };

  // useEffect(() => {
  if (getNode.data) {
    let idFolder = id === "" && site === "" ? "onedrive" : id;
    if (id === "" && site) idFolder = site;
    const newData = ajouterChildrenRecursif(data, idFolder || "", getNode.data);
    treeData = convertDataToTree(newData);
    updateData(newData);
  }
  // }, [getNode.isFetched]);
  // console.log(treeData);
  // console.log(getNode.data);

  const handleCancel = () => {
    change(false);
    setSite("");
    setId("");
  };

  return (
    <Modal
      style={{ top: 20 }}
      title=""
      width={560}
      centered={true}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      key="modalDrive"
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            key="back"
            size={"large"}
            onClick={handleCancel}
            style={{
              fontSize: "1rem",
              padding: "5px",
              textAlign: "center",
              width: "120px",
            }}
          >
            Fermer le drive
          </Button>
        </div>,
      ]}
    >
      <Container>
        {contextHolder}
        <Spin size="large" spinning={getNode.isLoading}>
          <div style={{ padding: "10px" }}>
            <Content>
              {isDirectory
                ? "Choix du dossier parent sur le drive"
                : "Choix du fichier sur le drive"}
            </Content>
            <Tree
              showLine={true}
              showIcon={showIcon}
              defaultExpandedKeys={["0-0-0"]}
              onSelect={onSelect}
              treeData={treeData}
              onExpand={onExpand}
            />
          </div>
        </Spin>
      </Container>
    </Modal>
  );
};

export default ModalFolderDrive;

interface modalProps {
  visible: boolean;
  change: Function;
  data: FileItem[];
  updateData: Function;
  updateFile: Function;
  fileSelected: CustomDataNode | null;
  idFileSelected: String;
  isDirectory?: boolean;
  drive?: string;
  idSite?: string;
  idRoot?: string;
}
interface CustomDataNode extends DataNode {
  url?: string;
  siteInDrive?: string | null;
}
interface CustomEventDataNode extends EventDataNode<DataNode> {
  siteInDrive?: string;
}
const Container = styled.div`
  // margin: auto;
  display: flex;
  flex-direction: column;
  // align-items: center;
  margin-top: 10px;
  // border: 1px solid black;
`;

const ContainerInput = styled(Space)`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.p`
  font-size: 1.02rem;
  width: 150px;
  text-align: end;
  align-items: left;
  font-weight: 650;
`;

const Content = styled.p`
  margin-top: 10px;
  background-color: #4e8ccb;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  text-tranform: uppercase;
  border-radius: 5px;
  padding: 3px;
  margin: 10px;
`;
