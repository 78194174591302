import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import InputFieldObj from "./InputFieldObj";
import { orderBy } from "lodash";
import styled from "styled-components";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import InputField from "./InputField";
interface Field {
  id: string;
  idConnecteur: null | string;
  idParent: string;
  idTypeChamp: string;
  nom: string;
  aide: string | null;
  libelle: string;
  position: number;
  requis: boolean;
  placeholder: string;
  isCallback: boolean;
  isConcat: boolean;
  multiple: boolean;
  createdDate: string;
  updatedDate: string;
  typeChamp: {
    id: string;
    nom: string;
    code: string;
    createdDate: string;
    updatedDate: string;
  };
}

const InputObjet: React.FC<InputObjetProps> = ({
  id,
  enfants = [],
  required,
  value,
  aide,
  setValue,
  fields,
  update,
  placeholder,
  libelle,
}) => {
  const Items: any[] = [];
  // console.log({ enfants });
  for (let index = 0; index < enfants.length; index++) {
    const element = enfants[index];
    Items.push({
      nom: element.nom,
      type: element.typeChamp.code,
      position: element.position,
      libelle: element.libelle,
      requis: element.requis,
      placeholder: element.placeholder,
      isCallback: element.isCallback,
      aide: element.aide,
      isConcat: element.isConcat,
    });
  }
  // console.log({ Items });
  const formDynamics = {};
  for (let index = 0; index < enfants.length; index++) {
    const element = enfants[index].nom;
    Object.assign(formDynamics, { [element]: "" });
  }
  const [AllInput, setAllInput] = useState<any[]>([]);
  const [fieldsData, setFieldsData] = useState<any>([]);

  const AddItems = () => {
    setAllInput((el) => [...el, Items]);
    setFieldsData((el: any) => [...el, formDynamics]);
  };
  const removeItem = (index: number) => {
    const items = [];
    for (let i = 0; i < AllInput.length; i++) {
      const element = AllInput[i];
      if (i === index) continue;
      items.push(element);
    }
    setAllInput(items);
    const elements = [];
    for (let i = 0; i < fieldsData.length; i++) {
      const element = fieldsData[i];
      if (i === index) continue;
      elements.push(element);
    }
    setFieldsData(elements);
  };

  useEffect(() => {
    setValue({ ...value, [id]: JSON.stringify(fieldsData) });
  }, [fieldsData]);

  useEffect(() => {
    if (update) {
      const data = JSON.parse(value[id] || "{}");

      if (
        Array.isArray(data) &&
        data.filter((el) => JSON.stringify(el) !== "{}").length >= 1
      ) {
        const Items: any = [];
        for (let index = 0; index < enfants.length; index++) {
          const element = enfants[index];
          Items.push({
            nom: element.nom,
            type: element.typeChamp.code,
            position: element.position,
            libelle: element.libelle,
            requis: element.requis,
          });
        }
        let champ = [Items];
        for (
          let index = 0;
          index < data.filter((el) => JSON.stringify(el) !== "{}").length - 1;
          index++
        ) {
          if (JSON.stringify(data[index]) !== "{}") {
            champ.push(Items);
          }
        }
        setAllInput(champ);
        setFieldsData(data.filter((el) => JSON.stringify(el) !== "{}"));
      }
    } else {
      if (required) {
        setAllInput([Items]);
        setFieldsData([formDynamics]);
      }
    }
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Title>
        <>
          {libelle} {required && <span style={{ color: "red" }}>*</span>}{" "}
        </>
        {aide && (
          <Tooltip
            title={() => <p style={{ color: "#444" }}> {aide} </p>}
            color={"white"}
          >
            <InfoCircleOutlined style={{ color: "#444", cursor: "pointer" }} />
          </Tooltip>
        )}
      </Title>

      <div>
        {AllInput &&
          AllInput.length >= 1 &&
          AllInput.map((item, index) => {
            return item && item.length >= 1 ? (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill,400px)",
                  width: "840px",
                  marginTop: 10,
                  padding: 10,
                  paddingBottom: 10,
                  position: "relative",
                  justifyContent: "space-between",
                  backgroundColor: "rgba(192,192,192,0.2)",
                  border: "1px solid 	rgba(192,192,192,0.1)",
                  borderRadius: "4px",
                }}
              >
                {!required || (required && AllInput.length > 1) ? (
                  <p
                    style={{
                      position: "absolute",
                      color: "red",
                      textTransform: "uppercase",
                      cursor: "pointer",
                      right: 10,
                    }}
                    onClick={() => removeItem(index)}
                  >
                    X
                  </p>
                ) : (
                  ""
                )}
                {item &&
                  item.length >= 1 &&
                  orderBy(item, "position", "asc").map((field: any, i) => {
                    // console.log({ field });
                    return (
                      <InputFieldObj
                        id={field?.nom}
                        index={index}
                        aide={field.aide}
                        required={field?.requis}
                        isCallback={field.isCallback}
                        libelle={field.libelle}
                        placeholder={field.placeholder}
                        value={fieldsData}
                        setValue={setFieldsData}
                        type={field.type}
                        isConcat={field.isConcat}
                        fields={fields || []}
                      />
                    );
                  })}
              </div>
            ) : null;
          })}
      </div>
      <Button onClick={AddItems}>Ajouter un groupe</Button>
      <Divider />
    </div>
  );
};
interface InputObjetProps {
  id: string;
  placeholder: string;
  value: ConnectorConfigInputState;
  type: string;
  libelle: string;
  aide: string | null;
  setValue: Function;
  fields: any[];
  required: boolean;
  enfants?: Field[];
  update?: boolean;
}
interface ConnectorConfigInputState {
  [id: string]: string;
}
const Title = styled.p`
  font-size: 1.02rem;
  margin-bottom: 0;
  font-weight: 650;
  align-self: flex-start;
`;
const Button = styled.button`
  width: 200px;
  align-self: flex-end;
  margin-top: 10px;
  padding: 10px;
  height: 40px;
  align-items: center;
  background: #4e8ccb;
  border-radius: 5px;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  color: white;
  border: 1px solid white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s all in ease;
  fontweight: bold;
  fontsize: 1.4em;
  &:hover {
    background: #4e8ddf;
    cursor: pointer;
  }
`;
export default InputObjet;
