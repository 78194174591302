import React, { useState, useEffect } from "react";
import { Modal, Spin, Switch } from "antd";
import { Button, Input, Space, Select, message, Checkbox } from "antd";
import styled from "styled-components";
import { getOneAccount, updateAccount, getWhiteserver } from "../api/account";
import { isEmail, isURL } from "class-validator";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllOrganisation } from "../api/organisation";
import { setDefaultResultOrder } from "dns";

const ModalModifiedAccount: React.FC<modalProps> = ({
  visible,
  change,
  id,
}) => {
  const queryClient = useQueryClient();
  // Recuperation des urls
  const WhiteServer = useQuery({
    queryKey: ["whiteserver"],
    queryFn: getWhiteserver,
  });

  // Recuperation des comptes
  const accountInfo = useQuery({
    queryKey: ["account", id],
    queryFn: getOneAccount,
    enabled: WhiteServer.isFetched,
  });

  const [other, setOther] = useState({ label: "Autre", value: "", key: "1" });
  const urls = [other];
  if (WhiteServer.isSuccess && WhiteServer.data) {
    for (let i = 0; i < WhiteServer.data.length; i++) {
      const element = WhiteServer.data[i];
      urls.push({ label: element.nom, value: element.url, key: element.id });
    }
  }
  // Recuperation des organisation
  const Organisations = useQuery({
    queryKey: ["Organisations"],
    queryFn: getAllOrganisation,
    enabled: accountInfo.isSuccess,
  });
  // Mise à jour d'une organisation
  const updateMutate = useMutation({
    mutationFn: updateAccount,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["Accounts"] });
      queryClient.invalidateQueries({ queryKey: ["account", id] });
      messageApi.open({
        type: "success",
        content: "Le compte a été modification avec succès",
        duration: 2,
      });
      handleCancel();
    },
    onError: (err: any, status) => {
      if (Number(err["request"]?.status) === 401) {
        messageApi.open({
          type: "warning",
          content: "Url non autorisée ",
          duration: 2,
        });
      } else {
        messageApi.open({
          type: "warning",
          content:
            "Une erreur c'est produite lors de la mise à jour du compte ",
          duration: 2,
        });
      }
    },
  });
  // Formatage des données des organisations
  const organisations = [];
  if (Organisations.isSuccess && Organisations.data) {
    for (let i = 0; i < Organisations.data.length; i++) {
      const element = Organisations.data[i];
      organisations.push({ label: element.nom, value: element.id });
    }
  }

  const [messageApi, contextHolder] = message.useMessage();
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [keyApiVisible, setkeyApiVisible] = React.useState(false);
  const [keySecretVisible, setkeySecretVisible] = React.useState(false);
  const [organisation, setOrganisation] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [keyApi, setKeyApi] = useState<string>("");
  const [keySecret, setKeySecret] = useState<string>("");
  const [api, setApi] = useState<string>("");
  // const [actif, setActif] = useState<boolean>(false);

  useEffect(() => {
    if (accountInfo.isSuccess) {
      setKeyApi(accountInfo.data?.cleApi);
      setKeySecret(accountInfo.data.cleSecret);
      setEmail(accountInfo.data?.email);
      setName(accountInfo.data?.nom);
      if (accountInfo.data?.api) {
        const url: string = accountInfo.data?.api || "";
        const el = urls?.find((u) => u.value === url);
        setApi(url);
        if (!el) {
          setOther({ label: "Autre", key: "1", value: url });
        }
      }
      setOrganisation(accountInfo.data.idOrganisation);
      // setActif(accountInfo.data?.actif);
    }
  }, [accountInfo.isFetched]);

  const submit = () => {
    try {
      if (!organisation.trim())
        throw new Error("Vous n'avez pas choisi d'organisation");
      if (!name.trim()) throw new Error("Vous n'avez pas entré de nom");
      if (!email.trim())
        throw new Error("Vous n'avez entré pas d'adresse mail");
      if (!isEmail(email.trim()))
        throw new Error("Veillez entrer une adresse mail valide");

      if (!api.trim())
        throw new Error("Vous n'avez entré pas d'url pour  l'api");
      if (!isURL(api.trim())) throw new Error("Veillez entrer une URL valide");
      if (!password.trim())
        throw new Error("Vous n'avez pas entrer de mot de passe");

      if (!keyApi.trim()) throw new Error("Vous n'avez pas entré de Clé API");
      if (!keySecret.trim())
        throw new Error("Vous n'avez pas entré de Clé Secrete");
    } catch (e: any) {
      messageApi.open({
        type: "warning",
        content: e.message ? e.message : "",
        duration: 2,
      });
      return;
    }
    const verif = organisation && name && keyApi && keySecret && email && api;

    if (verif) {
      updateMutate.mutate({
        idCompte: id,
        idOrganisation: organisation,
        nom: name,
        email,
        api: retirerDernierSlash(api),
        mdp: password,
        cleApi: keyApi,
        cleSecret: keySecret,
      });
    }
  };

  const retirerDernierSlash = (chaine: string): string => {
    // Trim de la chaîne de caractères
    chaine = chaine.trim();

    // Vérifie si la chaîne est vide après le trim
    if (chaine.length === 0) {
      return chaine;
    }

    // Vérifie si le dernier caractère est un '/'
    if (chaine.charAt(chaine.length - 1) === "/") {
      // Retire le dernier caractère
      return chaine.slice(0, -1);
    } else {
      return chaine;
    }
  };

  const handleOk = () => {
    clearData();
    change(false);
  };

  const handleCancel = () => {
    clearData();
    change(false);
  };

  // Cleaning the form
  const clearData = () => {
    setName("");
    setEmail("");
    setOrganisation("");
    setPassword("");
    setKeyApi("");
    setKeySecret("");
    setApi("");
    // setActif(false);
    setOther({ label: "Autre", value: "", key: "1" });
  };

  return (
    <Modal
      style={{ top: 20 }}
      title=""
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
      centered
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            key="back"
            size={"large"}
            onClick={handleCancel}
            style={{
              fontSize: "1rem",
              padding: "5px",
              textAlign: "center",
              width: "120px",
            }}
          >
            ANNULER
          </Button>
          <Button
            key="submit"
            size={"large"}
            style={{
              background: "#4e8ccb",
              color: "white",
              fontSize: "1rem",
              paddingLeft: "13px",
              paddingRight: "13px",
              textAlign: "center",
              width: "auto",
            }}
            loading={updateMutate.isPending || WhiteServer.isLoading}
            onClick={submit}
          >
            MODIFIER LE COMPTE
          </Button>
        </div>,
      ]}
    >
      <div style={{ padding: "10px" }}>
        <Spin tip="" size="large" spinning={accountInfo.isLoading}>
          <Content>MODIFICATION DU COMPTE</Content>
          <Container>
            <ContainerInput direction="horizontal">
              <Title>
                Organisation <span style={{ color: "red" }}>*</span> :{" "}
              </Title>
              <Select
                showSearch={true}
                optionFilterProp="label"
                style={{ width: 360 }}
                size="large"
                value={organisation}
                onChange={setOrganisation}
                options={organisations}
                loading={Organisations.isLoading}
              />
            </ContainerInput>
            <ContainerInput direction="horizontal">
              <Title>
                Nom du compte <span style={{ color: "red" }}>*</span> :
              </Title>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                size="large"
                style={{ height: 40, width: 360 }}
              />
            </ContainerInput>{" "}
            {/* <ContainerInput direction="horizontal">
              <Title>Actif : </Title>
              <Checkbox
                style={{ height: 20, width: 360 }}
                checked={actif}
                onClick={() => setActif((actif) => !actif)}
              />
            </ContainerInput> */}
            <ContainerInput direction="horizontal">
              <Title>
                Email <span style={{ color: "red" }}>*</span> :
              </Title>
              <Input
                placeholder="Saisir l'adresse mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="large"
                style={{ height: 40, width: 360 }}
              />
            </ContainerInput>
            <ContainerInput direction="horizontal">
              <Title>
                Url Api <span style={{ color: "red" }}>*</span> :
              </Title>
              <Select
                placeholder="Choisir votre une url"
                showSearch={true}
                optionFilterProp="label"
                style={{ width: 360 }}
                size="large"
                value={api}
                onChange={(val) => {
                  setApi(val);
                }}
                options={urls}
                loading={WhiteServer.isLoading}
              />
            </ContainerInput>
            <ContainerInput direction="horizontal">
              <Title></Title>

              <Input
                value={api}
                disabled={Boolean(
                  urls?.find((u) => u.value === api)?.key !== "1"
                )}
                onChange={(e) => {
                  const el = urls?.find((u) => u.value === e.target.value);
                  if (el) {
                    setApi(el.value);
                  } else {
                    setOther({
                      label: "Autre",
                      value: e.target.value,
                      key: "1",
                    });
                    setApi(e.target.value);
                  }
                }}
                size="large"
                placeholder="Saisir l'adresse url"
                style={{ height: 40, width: 360 }}
              />
            </ContainerInput>
            <ContainerInput direction="horizontal">
              <Title>
                Mot de passe <span style={{ color: "red" }}>*</span> :
              </Title>
              <Input.Password
                placeholder="Saisir le mot de passe"
                size="large"
                style={{ height: 40, width: 360 }}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
              />
            </ContainerInput>
            <ContainerInput direction="horizontal">
              <Title>
                Clé API <span style={{ color: "red" }}>*</span> :{" "}
              </Title>
              <Input.Password
                size="large"
                placeholder="Saisir la clé API"
                style={{ height: 40, width: 360 }}
                value={keyApi}
                onChange={(e) => setKeyApi(e.target.value)}
                visibilityToggle={{
                  visible: keyApiVisible,
                  onVisibleChange: setkeyApiVisible,
                }}
              />
            </ContainerInput>
            <ContainerInput direction="horizontal">
              <Title>
                Clé Secrete <span style={{ color: "red" }}>*</span> :{" "}
              </Title>
              <Input.Password
                size="large"
                placeholder="Saisir la Clé Secrete"
                style={{ height: 40, width: 360 }}
                value={keySecret}
                onChange={(e) => setKeySecret(e.target.value)}
                visibilityToggle={{
                  visible: keySecretVisible,
                  onVisibleChange: setkeySecretVisible,
                }}
              />
            </ContainerInput>
          </Container>
        </Spin>
      </div>
      {contextHolder}
    </Modal>
  );
};

export default ModalModifiedAccount;
const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerInput = styled(Space)`
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.p`
  font-size: 1.02rem;
  width: 150px;
  text-align: end;
  align-items: left;
  font-weight: 650;
`;

const Content = styled.p`
  margin-top: 10px;
  background-color: #4e8ccb;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  text-tranform: uppercase;
  border-radius: 5px;
  padding: 3px;
`;
interface modalProps {
  visible: boolean;
  change: Function;
  id: string;
}
